<template>
  <div class="row mx-0 justify-content-center" >
    <div class="col-12 mb-3 text-center font19" >
      Donor Details
    </div>
    <div class="col-12 mb-3 text-center" >
      You're making this donation as:
    </div>
    <UserDisplay :imageUrl="imageUrl" :sadaqahUserDonatingType="sadaqahUserDonatingType" :donatingAs="donatingAsValue" />
    <div class="col-10 my-4 text-center divider">
    </div>
    <ChangeDonor @updateDonor="setUserDetails()" :sadaqahUserDonatingType="sadaqahUserDonatingType" />
  </div>
</template>

<script>
import { defineAsyncComponent } from 'vue'
import { mapGetters } from 'vuex'

export default {
  components: {
    ChangeDonor: defineAsyncComponent(() => import('./views/ChangeDonor.vue')),
    UserDisplay: defineAsyncComponent(() => import('./views/UserDisplay.vue'))
  },
  name: 'User Details',
  props: ['donatingAs', 'sadaqahUserDonatingType'],
  emits: ['update:donatingAs'],
  data () {
    return {
      imageUrl: null
    }
  },
  async mounted () {
    await this.setUserDetails()
  },
  computed: {
    ...mapGetters([
      'user', 'donateAs'
    ]),
    donatingAsValue: {
      get () {
        return this.donatingAs
      },
      set (val) {
        this.$emit('update:donatingAs', val)
      }
    }
  },
  methods: {
    setUserDetails () {
      console.log('setUserDetails')
      if (this.donateAs) {
        if (this.sadaqahUserDonatingType === 'organisation') {
          this.donatingAsValue.organisationID = this.donateAs.organisationID
          this.donatingAsValue.displayName = this.donateAs.registeredName
          this.donatingAsValue.displayID = this.donateAs.organisationID
          this.donatingAsValue.address.addressLine1 = this.donateAs.addressList[0].addressLine1
          this.donatingAsValue.address.town = this.donateAs.addressList[0].town
          this.donatingAsValue.address.country = this.donateAs.addressList[0].country
          this.donatingAsValue.address.postalCode = this.donateAs.addressList[0].postalCode
        } else {
          this.donatingAsValue.userID = this.donateAs.userID
          this.donatingAsValue.displayName = this.donateAs.firstName + ' ' + this.donateAs.lastName
          this.donatingAsValue.displayID = this.donateAs.donorID
          this.donatingAsValue.address.addressLine1 = this.donateAs.postalAddress1
          this.donatingAsValue.address.town = this.donateAs.town
          this.donatingAsValue.address.country = this.donateAs.country ? this.donateAs.country : null
          this.donatingAsValue.address.postalCode = this.donateAs.postalcode
        }
        this.imageUrl = this.donateAs.imageUrl ? this.donateAs.imageUrl : null
      } else if (this.user) {
        this.donatingAsValue.userID = this.user.userID ? this.user.userID : null
        this.imageUrl = this.user.imageUrl ? this.user.imageUrl : null
        this.donatingAsValue.displayName = this.user.firstName + ' ' + this.user.lastName
        this.donatingAsValue.displayID = this.user.donorID
        this.donatingAsValue.address.addressLine1 = this.user.postalAddress1
        this.donatingAsValue.address.town = this.user.town
        this.donatingAsValue.address.country = this.user.country ? this.user.country : null
        this.donatingAsValue.address.postalCode = this.user.postalcode
      }
    }
  }
}
</script>
<style scoped>
.divider {
  border-bottom: 2px solid var(--green-color-dark);
}
</style>
